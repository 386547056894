/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React, { useEffect, useRef, useState }  from 'react';
import { Container, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import {ContentMirrorFooterView, BackToTopView} from '@glisco/custom-blocks';

// import './footer.less';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});


// function to get current year from current date
const getCurrentYear = () => {
  const date = new Date();
  return date.getFullYear();
};

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
// const Footer = ({ intl }) => (
//   <Segment
//     role="contentinfo"
//     vertical
//     padded
//     inverted
//     color="grey"
//     textAlign="center"
//     id="footer"
//   >
//     <Container>
//       <Segment basic inverted color="grey" className="discreet">
//         <FormattedMessage
//           id="The {plonecms} is {copyright} 2000-{current_year} by the {plonefoundation} and friends."
//           defaultMessage="The {plonecms} is {copyright} 2000-{current_year} by the {plonefoundation} and friends."
//           values={{
//             plonecms: (
//               <FormattedMessage
//                 id="Plone{reg} Closed Source CMS/WCM"
//                 defaultMessage="Plone{reg} Closed Source CMS/WCM"
//                 values={{ reg: <sup>®</sup> }}
//               />
//             ),
//             copyright: (
//               <abbr title={intl.formatMessage(messages.copyright)}>©</abbr>
//             ),
//             current_year: new Date().getFullYear(),
//             plonefoundation: (
//               <a className="item" href="http://plone.org/foundation">
//                 <FormattedMessage
//                   id="Plone Foundation"
//                   defaultMessage="Plone Foundation"
//                 />
//               </a>
//             ),
//           }}
//         />{' '}
//         <FormattedMessage
//           id="Distributed under the {license}."
//           defaultMessage="Distributed under the {license}."
//           values={{
//             license: (
//               <a
//                 className="item"
//                 href="http://creativecommons.org/licenses/GPL/2.0/"
//               >
//                 <FormattedMessage
//                   id="GNU GPL license"
//                   defaultMessage="GNU GPL license"
//                 />
//               </a>
//             ),
//           }}
//         />
//       </Segment>
//       <List horizontal inverted>
//         {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
//         <div role="listitem" className="item">
//           <Link className="item" to="/sitemap">
//             <FormattedMessage id="Site Map" defaultMessage="Site Map" />
//           </Link>
//         </div>
//         <div role="listitem" className="item">
//           <Link className="item" to="/accesibility-info">
//             <FormattedMessage
//               id="Accessibility"
//               defaultMessage="Accessibility"
//             />
//           </Link>
//         </div>
//         <div role="listitem" className="item">
//           <Link className="item" to="/contact-form">
//             <FormattedMessage id="Contact" defaultMessage="Contact" />
//           </Link>
//         </div>
//         <div role="listitem" className="item">
//           <a className="item" href="https://plone.com">
//             <FormattedMessage
//               id="Powered by Plone & Python"
//               defaultMessage="Powered by Plone & Python"
//             />
//           </a>
//         </div>
//       </List>
//     </Container>
//   </Segment>
// );

const Footer = (props) => {

  return <Segment
    role="contentinfo"
    vertical
    padded
    inverted
    color="grey"
    textAlign="center"
    id="footer"
  >
    <Container>
      <div className="footerTopSection">

        <div className="footerAbout">
          <div className="aboutGT">
            <h3>GT Portugal</h3>
          </div>

          <div className="icons">
            <ul>
            <li><a href="https://goo.gl/maps/iKDv48icjeRDM4mh8" target='new'><img src="/icons/Location.svg" alt="location"></img></a></li>
            <li><a href="mailto:gtportugal@gtportugal.com" target='new' title="gtportugal@gtportugal.com"><img src="/icons/Email.svg" alt="email"></img></a></li>
            <li><a href="https://www.linkedin.com/company/gt-portugal/" target='new'><img src="/icons/Linkedin.svg" alt="linkedin"></img></a></li>
            <li><a href="tel:+351229024702" target='new' title="+351229024702"><img src="/icons/Telephone.svg" alt="+351229024702"></img></a></li>
            </ul>
          </div>

        </div>

        <div className="footerCategoriesWrapper" key="footerlinks">
          <ContentMirrorFooterView/>
        </div>

      </div>

      <div className="footer-portugal2020" key="pt2020">
        <a href="/about-us/fichaoperacao_norte2030_gt-portugal.pdf/@@download/file"><img src="/icons/barra_assinaturas_portugal_2030.png" alt="Apoio Norte 2030"></img></a>
      </div>

      <div className="footerBottomSection" key="copywrite">
        <div className="copyright">
          © 2021-{getCurrentYear()} GT Portugal. All Rights Reserved.
        </div>

        <div className="privacy-policy" key="privacy">
          <a href="/about-us/privacy-policy">Privacy Policy</a>
        </div>

        <div className="terms-of-service" key="tos">
          <a href="/about-us/terms-of-service">Terms of Service</a>
        </div>

        <div className="login-button" key="login">
          <a aria-label="login" href="/login?return_url=/">Log in</a>
        </div>
      </div>
      <div className="showOnScroll" id="backToTop">
        <BackToTopView/>
      </div>
    </Container>
  </Segment>
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
